import { Navigate, createBrowserRouter } from 'react-router-dom'
import ErrorPage from './pages/error'

export interface Handle {
  footerVisible?: boolean
}

export const router = createBrowserRouter([
  {
    path: '/*',
    lazy: () => import('./pages/root'),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'sign-in',
        element: <Navigate to="/login" />,
      },
      {
        path: 'login',
        lazy: () => import('./pages/login'),
      },
      {
        path: 'terms',
        lazy: () => import('./pages/md'),
      },
      {
        path: 'privacy',
        lazy: () => import('./pages/md'),
      },
      {
        path: '*',
        lazy: () => import('./pages/app/index'),
        children: [
          {
            path: '',
            lazy: () => import('./pages/app/home'),
          },
          {
            path: 'bots/yours',
            lazy: () => import('./pages/app/your-bots'),
          },
          {
            path: 'bot/:botId/edit',
            lazy: () => import('./pages/app/edit-bot'),
          },
          {
            path: 'bot/create',
            lazy: () => import('./pages/app/edit-bot'),
          },
          {
            handle: { footerVisible: false },
            path: 'bot/:botId',
            lazy: () => import('./pages/app/chat'),
          },
          {
            handle: { footerVisible: false },
            path: 'chat/:chatId?',
            lazy: () => import('./pages/app/chat'),
          },
          {
            path: 'edit-bot',
            element: <Navigate to="/bot/create" replace />,
          },
          {
            path: '*',
            element: <Navigate to="/" />,
          },
        ],
      },
    ],
  },
])
