import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { Link, useRouteError } from 'react-router-dom'

function ErrorPage() {
  const { t } = useTranslation()
  const error = useRouteError()

  console.error('uncaught error', error)

  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center">
      <p className="text-4xl font-bold">{t('error.page.title')}</p>
      <div className="p-4 flex gap-4">
        <Button asChild>
          <Link to="/">{t('error.page.back')}</Link>
        </Button>

        <Button variant="secondary" onClick={() => window.location.reload()}>
          {t('error.page.refresh')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
