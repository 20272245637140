export const setVisibleHeight = () => {
  const set = () => {
    document.documentElement.style.setProperty(
      '--visible-height',
      `${window.innerHeight}px`,
    )
  }
  set()
  window.addEventListener('resize', set)
}
