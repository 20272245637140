import { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { DynamicCreateElementContainer } from 'redyc'
import { LoadingPage } from './components/ui/loading'
import { Toaster } from './components/ui/sonner'
import { setVisibleHeight } from './lib/visible-height'
import { router } from './router'

setVisibleHeight()

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <DynamicCreateElementContainer>
        <RouterProvider router={router} fallbackElement={<LoadingPage />} />
        <Toaster richColors position="top-right" />
      </DynamicCreateElementContainer>
    </Suspense>
  )
}

export default App
