import './loading.css'

export const Loading = () => {
  return (
    <div className="spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  )
}

export const LoadingPage = () => {
  return (
    <div className="absolute inset-0 flex justify-center items-center">
      <Loading />
    </div>
  )
}
